import { ImageSlider } from "./ImageSlider"

export const Header = (props) => {
  return (
    // <header id='header'>
    //   <div className='intro'>
    //     <div className='overlay' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/img/bg.jpg'})` }}>
    //       <div className='container'>
    //         <div className='row'>
    //           <div className='col-md-8 col-md-offset-2 intro-text'>
    //             <h1>
    //               {props.data ? props.data.title : 'Loading'}
    //               <span></span>
    //             </h1>
    //             <p>{props.data ? props.data.paragraph : 'Loading'}</p>
    //             <a
    //               href='ourservices'
    //               className='btn btn-custom btn-lg page-scroll'
    //             >
    //               Learn More
    //             </a>{' '}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>
    <ImageSlider/>
  )
}
